import React from 'react';
import { Helmet } from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';

// tslint:disable no-default-export
export default () => (
  <>
    <Helmet title="Home" />

    <Intro>
      <Highlight>Ponente</Highlight> is a marketing agency.
    </Intro>

    <BlockText
      heading="Who we are"
      description={
        <>
          Ponente is a full-service agency. We build strategies, create content, and execute campaigns.
         
        </>
      }
    />
  </>
);
